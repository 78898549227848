import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import axios from "axios";

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      const access_token = localStorage.getItem("access_token");
      if (!access_token) {
        setIsAuthenticated(false);
        return;
      }
      try {
        await axios.post(`${process.env.REACT_APP_DEV_BACK_URL}/token/verify`, { access_token });
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Token verification error:", error);
        await refreshToken();
      }
    };
    verifyToken();
  }, []);

  const refreshToken = async () => {
    try {
      const refresh_token = localStorage.getItem("refresh_token");
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_BACK_URL}/token/verify`,
        { access_token: localStorage.getItem("access_token") },
        { headers: { "Refresh-Token": refresh_token } }
      );
      if (response.data.tokens) {
        localStorage.setItem("access_token", response.data.tokens.AccessToken);
        localStorage.setItem("refresh_token", response.data.tokens.RefreshToken);
        setIsAuthenticated(true);
      } else {
        handleLogout();
      }
    } catch (refreshError) {
      console.error("Token refresh error:", refreshError);
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setIsAuthenticated(false);
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;