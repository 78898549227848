import React, { useState, useEffect } from "react";
import { Box, Text, Image, Flex, SimpleGrid, useToast } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import SignUpGoogle from "../Components/SignupGoogle";
import Navbar from "../Components/Navbar";
import { Link, useNavigate } from "react-router-dom";
// Assuming you have these images imported
import image1 from "../assets/landingPage/image1.png";
import image2 from "../assets/landingPage/image2.png";
import image3 from "../assets/landingPage/image3.png";
import image4 from "../assets/landingPage/image4.png";
import image5 from "../assets/landingPage/image5.png";
import image6 from "../assets/landingPage/image6.png";
import image7 from "../assets/landingPage/image7.png";
import image8 from "../assets/landingPage/image8.png";
import hdfclogo from "../assets/landingPage/hdfclogo.png";
import api from "../utils/api";
import { getTransactions } from "../utils/dataProcessing";

const LandingPage = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
  ];
  const navigate = useNavigate();
  const toast = useToast()
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    console.log("useffect called")
    const checkTokensAndLogin = async () => {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      
      if (accessToken && refreshToken) {
        console.log("access refresh token got")
        try {
          // Attempt to verify the token
          const response = await api.post('/token/verify', 
            { access_token: accessToken },
            { headers: { 'Refresh-Token': refreshToken } }
          );
          
          if (response.data.message === "Token is valid" || response.data.tokens) {
            console.log("token is valid")
            // If tokens were refreshed, update them in localStorage
            if (response.data.tokens) {
              localStorage.setItem('access_token', response.data.tokens.AccessToken);
              localStorage.setItem('refresh_token', response.data.tokens.RefreshToken);
            }
            
            // Check if transactionDB is present
            const transactions = await getTransactions();
            if (transactions && transactions.length > 0) {
              console.log("auto login succ transaction Db there")
              navigate('/analytics');
            } else {

              navigate('/loading');
            }
          }
        } catch (error) {
          console.error('Error during auto-login:', error);
          console.log('Error during auto-login:', error);
          // If there's an error (e.g., both tokens are invalid), clear localStorage and stay on landing page
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          toast({
            title: "Auto-login failed",
            description: "Please sign in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    checkTokensAndLogin();
  }, [navigate, toast]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 2 : prevIndex - 2
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 2 >= images.length ? 0 : prevIndex + 2
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 7000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Box bg="#303A43" minHeight="100vh" color="white">
      <Navbar />
      <Box pt="80px" px="20px">
        {" "}
        {/* Increased top padding to prevent overlap */}
        <Flex direction="column" align="center" maxWidth="screen" mx="auto">
          <Text fontSize="4xl" fontWeight="bold" mb="2">
            <span className="text-white p-2">Monitor all your</span>
            <span className="text-green-500 p-2">UPI</span>
            <span className="text-white p-2">Transactions at one place!</span>
          </Text>
          <Text fontSize="2xl" fontWeight="bold" mb="6">
            "We do not store your DATA"
          </Text>
          <SignUpGoogle />
          <Text fontSize="xl" mb="6" mt="4" className="text-center">
            <span className="text-grey-200 ">Login with your</span>
            <span className="text-red-500 px-2">HDFC</span>
            <span className="text-grey-200">Gmail Linked Account!</span>
          </Text>
          {/* Carousel Container */}
          <Box position="relative" width="100%" overflow="hidden" mb="10">
            <SimpleGrid columns={2} spacing={6}>
              {images
                .slice(currentIndex, currentIndex + 2)
                .map((src, index) => (
                  <Box
                    key={index}
                    position="relative"
                    paddingTop="75%"
                    width="100%"
                  >
                    {" "}
                    {/* 4:3 aspect ratio */}
                    <Image
                      src={src}
                      alt={`Carousel image ${index + 1}`}
                      position="absolute"
                      top="0"
                      left="0"
                      width="100%"
                      // height="100%"
                      objectFit="cover"
                      borderRadius="lg"
                      shadow="xl"
                    />
                  </Box>
                ))}
            </SimpleGrid>

            {/* Navigation Arrows */}
            <Flex
              position="absolute"
              top="50%"
              left="0"
              right="0"
              transform="translateY(-50%)"
              justify="space-between"
              px="4"
            >
              <Box
                as="button"
                onClick={handlePrev}
                bg="rgba(0,0,0,0.5)"
                color="white"
                borderRadius="full"
                p="3"
                _hover={{ bg: "rgba(0,0,0,0.7)" }}
              >
                <ChevronLeftIcon size={24} />
              </Box>
              <Box
                as="button"
                onClick={handleNext}
                bg="rgba(0,0,0,0.5)"
                color="white"
                borderRadius="full"
                p="3"
                _hover={{ bg: "rgba(0,0,0,0.7)" }}
              >
                <ChevronRightIcon size={24} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <footer className="bg-neutral-900 py-3">
        <div className="container mx-auto text-gray-400 text-center">
          <p>&copy; 2024 MoneyLite. All rights reserved.</p>
          <p className="mt-2 text-sm">
            <Link
              to="/privacy-policy/"
              className="text-green-500 hover:underline mr-2"
            >
              Privacy Policy
              
            </Link>
          </p>
        </div>
      </footer>
    </Box>
  );
};

export default LandingPage;
