import { ChakraProvider } from '@chakra-ui/react'
import { Route, Routes } from 'react-router-dom';
import LandingPage from './Pages/LandingPage';
import Home from './Pages/Home';
import ProtectedRoute from './Components/ProtectedRoute';
import ProtectedLayout from './Components/ProtectedLayout';
import Analytics from './Pages/Analytics';
// import Profile from './Pages/Profile';
import Subscriptions from './Pages/Subscriptions';
import InitialDataLoader from './Components/InitialDataLoader';
// import Gallery from './Pages/Gallery';
// import About from './Pages/About';
import ResponsiveWrapper from './Components/ResponsiveWrapper';
import GoogleOAuthPolicy from './Components/GoogleAuthPolicy';
import NotFound from './Components/NotFound';

function App() {
  return (
    <ChakraProvider>
            <ResponsiveWrapper>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route exact path="/privacy-policy/" element={<GoogleOAuthPolicy />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/gallery" element={<Gallery />} /> */}
        <Route element={<ProtectedRoute/>}>
            <Route path="/loading" element={<InitialDataLoader />} />
          <Route element={<ProtectedLayout />}>
            <Route path="/graphs" element={<Home />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} /> {/* Add this line for the 404 route */}
      </Routes>
      </ResponsiveWrapper>
    </ChakraProvider>
  );
}

export default App;
