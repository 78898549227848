import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import userImage from "../assets/images/user.png";
import { FaBars } from "react-icons/fa"; // For the menu icon
import api from "../utils/api";


const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to control sidebar visibility
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/user");
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle sidebar
  };
  const closeSidebar = () => {
    if (window.innerWidth < 1024) { // Close sidebar only for mobile (lg breakpoint)
      setIsOpen(false);
    }
  };
  return (
    <div className="relative min-h-screen ">
      {/* Mobile menu button */}
      <div className="lg:hidden p-4 z-50 fixed">
        <button
          onClick={toggleSidebar}
          className="text-black hover:text-neutral-600 focus:outline-none"
        >
          <FaBars size={24} color="black" /> {/* Changed icon color */}
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`bg-neutral-800 text-white w-52 h-full p-4 absolute lg:relative lg:translate-x-0 transform transition-transform duration-300 ease-in-out z-50 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="mb-8">
        <img
            src={user?.profileImageURL || userImage}
            alt="User"
            className="w-24 h-24 rounded-full mx-auto mb-4 object-cover"
          />
          <h2 className="text-center">{user?.name || "Hello!"}</h2>
        </div>
        <nav>
          <ul>
            <li className="mb-4">
              <Link
                to="/analytics"
                onClick={closeSidebar}
                className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
              >
                Analytics
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/graphs"
                onClick={closeSidebar}
                className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
              >
                Graphs
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/subscriptions"
                onClick={closeSidebar}
                className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
              >
                Subscriptions
              </Link>
            </li>
            {/* <li className="mb-4">
              <Link
                to="/profile"
                onClick={closeSidebar}
                className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
              >
                Profile
              </Link>
            </li> */}
            <li className="mb-4">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSePYSYotK4TyP4Ip3Z8mL3NMptJeP3nXAewFb6btayGjmm9ug/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
            className="block py-2 px-4 hover:bg-neutral-600 active:bg-neutral-300 focus:outline-none focus:ring focus:ring-neutral-100 focus:bg-neutral-600 rounded"
            >
            ContactUs/Report
          </a>
          </li>
            <li>
              <LogoutButton />
            </li>
          </ul>
        </nav>
        <div className="absolute bottom-0 left-0 right-10 p-4 flex justify-center items-center">
          <div className="text-2xl opacity-10">MoneyLite</div>
        </div>
      </div>

      {/* Overlay to close sidebar when clicked outside */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-10 lg:hidden z-40" // Ensures overlay appears behind the menu button
          onClick={toggleSidebar}
        />
      )}
    </div>
  );
};

export default Sidebar;