import React, { useEffect, useState, useCallback } from 'react';
import { Spinner, Center, VStack, Text, Button, Box, useToast } from '@chakra-ui/react';
import { processAndStoreData, getTransactions } from '../utils/dataProcessing';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';

const financialTips = [
  "Track your spending to understand your financial habits.",
  "Set clear financial goals to guide your decisions.",
  "Build an emergency fund for unexpected expenses.",
  "Invest in your future by contributing to retirement accounts.",
  "Live below your means to increase your savings.",
  "Pay off high-interest debt as quickly as possible.",
  "Learn about personal finance to make informed decisions.",
  "Review your subscriptions and cancel unnecessary ones.",
  "Use budgeting apps to help manage your finances.",
  "Consider multiple streams of income for financial stability."
];

const loadingMessages = [
  "We're fetching your data. Don't close the window!",
  "Almost there! Please wait a moment longer.",
  "Crunching the numbers... This may take a bit.",
  "Your financial insights are on their way!",
  "Just a few more seconds to financial clarity!"
];

const InitialDataLoader = () => {
  const [error, setError] = useState(null);
  const [tipIndex, setTipIndex] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const toast = useToast();

  const updateProgress = useCallback(() => {
    setProgress(prevProgress => {
      if (prevProgress >= 90) return prevProgress;
      return prevProgress + (90 - prevProgress) * 0.1;
    });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setError(null);
      setIsLoading(true);
      setProgress(0);

      const progressInterval = setInterval(updateProgress, 1000);

      const response = await api.get('/analytics/mobile/initial-load', {
        timeout: 60000, // 60 seconds timeout
      });
      const data = response.data;

      const chunkSize = 100;
      const chunks = Math.ceil(Object.keys(data.data).length / chunkSize);

      for (let i = 0; i < chunks; i++) {
        const chunk = Object.fromEntries(
          Object.entries(data.data).slice(i * chunkSize, (i + 1) * chunkSize)
        );
        await processAndStoreData({ data: chunk });
      }

      const storedTransactions = await getTransactions();
      console.log(`Stored ${storedTransactions.length} transactions`);
      
      clearInterval(progressInterval);
      setProgress(100);
      
      setTimeout(() => {
        setIsLoading(false);
        navigate('/analytics');
      }, 1000); // Give a second for the user to see 100%
    } catch (error) {
      console.error('Error during data fetching or processing:', error);
      setError(`An error occurred while loading your data. Please try again.`);
      setIsLoading(false);
      setProgress(0);
      
      toast({
        title: "Error",
        description: "There was an issue loading your data. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [navigate, toast, updateProgress]);

  useEffect(() => {
    fetchData();

    const tipInterval = setInterval(() => {
      setTipIndex((prevIndex) => (prevIndex + 1) % financialTips.length);
    }, 8000);

    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 5000);

    return () => {
      clearInterval(tipInterval);
      clearInterval(messageInterval);
    };
  }, [fetchData]);

  if (error) {
    return (
      <Center height="100vh">
        <VStack spacing={4}>
          <Text color="red.500">{error}</Text>
          <Button onClick={fetchData} isLoading={isLoading}>
            Retry
          </Button>
        </VStack>
      </Center>
    );
  }

  return (
    <Center height="100vh">
      <VStack spacing={8} maxWidth="600px" textAlign="center">
        <Spinner size="xl" />
        <Text fontSize="xl" fontWeight="bold">{loadingMessages[messageIndex]}</Text>
        <Box width="100%">
          <Text mb={2}>Progress: {Math.round(progress)}%</Text>
          <Box width="100%" height="8px" bg="gray.200" borderRadius="full">
            <Box 
              width={`${progress}%`} 
              height="100%" 
              bg="blue.500" 
              borderRadius="full" 
              transition="width 0.5s ease-in-out"
            />
          </Box>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>Financial Tip:</Text>
          <Text>{financialTips[tipIndex]}</Text>
        </Box>
      </VStack>
    </Center>
  );
};

export default InitialDataLoader;