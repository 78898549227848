import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import google_png from "../assets/images/googleTransaparentBg-removebg-preview.png"
import { Button, Image, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { motion } from 'framer-motion';


const GoogleAuth = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const MotionButton = motion(Button);

  const navigate = useNavigate();
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      const { code } = codeResponse;

      try {
        const response = await axios.post(`${process.env.REACT_APP_DEV_BACK_URL}/auth/google/sign-up`, {
          code,
        });
        localStorage.setItem('access_token', response.data.tokens.access_token);
        localStorage.setItem('refresh_token', response.data.tokens.refresh_token);     
        navigate('/loading');
      } catch (error) {
        console.error('Error during authentication', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
      }
    },
    onError: (error) => console.error('Google login failed', error),
    scope: 'https://www.googleapis.com/auth/gmail.readonly email profile',
  });

  return (
    <>
    <div className='flex flex-row justify-center items-center'>
      <MotionButton
        leftIcon={<Image src={google_png} alt="Google" boxSize={isLargerThan768 ? "20px" : "16px"} />}
        onClick={() => login()}
        size={isLargerThan768 ? "md" : "sm"}
        whileHover={{ 
          scale: 1.05,
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)"
        }}
        transition={{ duration: 0.2 }}
      >
        Sign up with Google
      </MotionButton>
    </div>
    </>
  )
};

export default GoogleAuth;