import React from 'react';
import { Box, Heading, Text, Container, UnorderedList, ListItem } from '@chakra-ui/react';

const GoogleOAuthPolicy = () => {
  return (
    <Container maxW="4xl" py={8}>
      <Box borderWidth={1} borderRadius="lg" p={6}>
        <Heading as="h1" size="xl" mb={4}>Privacy Policy</Heading>
        
        <Text mb={4}>
          This Privacy Policy outlines how our application collects, uses, and protects your personal information when you use our service. We are committed to ensuring the privacy and security of your data.
        </Text>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Information We Collect</Heading>
        <Text mb={2}>
          When you authenticate with Google, we request access to the following information:
        </Text>
        <UnorderedList mb={4}>
          <ListItem>Your email address</ListItem>
          <ListItem>Your name</ListItem>
          <ListItem>Your Gmail (read-only access)</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>How We Use Your Information</Heading>
        <Text mb={2}>
          We use the collected information solely for the purpose of providing you with our service, which includes:
        </Text>
        <UnorderedList mb={4}>
          <ListItem>Gmail fetching for UPI transactions</ListItem>
          <ListItem>Analyzing transaction data to provide insights</ListItem>
          <ListItem>Communicating with you about our service</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Data Protection and Security Measures</Heading>
        <Text mb={2}>
          We take the protection of your sensitive data seriously. Our data protection mechanisms include:
        </Text>
        <UnorderedList mb={4}>
          <ListItem>No persistent storage: We do not store any Gmail data or transaction information in our databases or caches.</ListItem>
          <ListItem>Temporary processing: Data is only held in memory for the duration of the analysis process.</ListItem>
          <ListItem>Encryption in transit: All data transmitted between our servers and your device is encrypted using industry-standard SSL/TLS protocols.</ListItem>
          <ListItem>Minimal data retention: We only retain the information necessary to provide our service and comply with legal obligations.</ListItem>
          <ListItem>Regular security audits: We conduct periodic reviews of our systems to ensure the highest level of data protection.</ListItem>
          <ListItem>Access controls: Only authorized personnel have access to the systems processing your data.</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Data Processing</Heading>
        <Text mb={4}>
          When you use our service, we process your Gmail data to extract UPI transaction information. This processing is done in real-time, and the results are immediately returned to you. We do not retain any of this processed data after the analysis is complete.
        </Text>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Third-Party Access</Heading>
        <Text mb={4}>
          We do not share your personal information or any data derived from your Gmail with any third parties, except as required by law or with your explicit consent.
        </Text>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Your Rights and Choices</Heading>
        <Text mb={2}>
          You have the following rights regarding your data:
        </Text>
        <UnorderedList mb={4}>
          <ListItem>Access: You can request access to the personal information we hold about you.</ListItem>
          <ListItem>Correction: You can request correction of any inaccurate information.</ListItem>
          <ListItem>Deletion: You can request the deletion of your data from our systems.</ListItem>
          <ListItem>Withdrawal of consent: You can withdraw your consent for data processing at any time.</ListItem>
        </UnorderedList>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Changes to This Policy</Heading>
        <Text mb={4}>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
        </Text>
        
        <Heading as="h2" size="lg" mt={6} mb={2}>Contact Us</Heading>
        <Text mb={4}>
          If you have any questions about this Privacy Policy or our data practices, please contact us at: madmecodes@gmail.com
        </Text>
        
        <Text mt={6} fontStyle="italic">
          Last updated: 23rd September 2024
        </Text>
      </Box>
    </Container>
  );
};

export default GoogleOAuthPolicy;