// src/utils/api.js
import axios from 'axios';

// Create an instance of axios
const api = axios.create({
  baseURL: `${process.env.REACT_APP_DEV_BACK_URL}`, // Replace with your backend URL
});

// Attach JWT token and refresh token to each request
api.interceptors.request.use(
  (config) => {
    // Get the JWT token and refresh token from localStorage
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    
    if (refreshToken) {
      config.headers['Refresh-Token'] = refreshToken;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = await axios.post(
          `${process.env.REACT_APP_DEV_BACK_URL}/api/token/verify`,
          { access_token: localStorage.getItem('access_token') },
          { headers: { 'Refresh-Token': refreshToken } }
        );

        const { access_token, refresh_token } = response.data.tokens;

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);

        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh fails, logout the user or handle as needed
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        // Redirect to login page or dispatch a logout action
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;